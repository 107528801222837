<script>
export default {
	name: 'StepLayer',
	data() {
		return {
			step: 1,
		};
	},
	methods: {
		handleNextStep() {
			this.step = this.step + 1;
		},
		handlePreviousStep() {
			this.step = this.step - 1;
		},
		clearStep() {
			this.step = 0;
		},
	},
};
</script>

<style scoped></style>
