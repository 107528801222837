export const validation = {
	// 설치비(도입비) 원가, 할인가 setting
	data() {
		return {
			domainCheckStatus: {
				VALID_DOMAIN: '사용할 수 있는 도메인',
				INVALID_DOMAIN: '정상적이지 않은 포맷',
				DUPLICATED_DOMAIN: '이미 사용중인 도메인',
				DUPLICATED_VIRTUAL_DOMAIN: '이미 사용중인 도메인',
				LIMITED_DOMAIN: '사용할 수 없는 도메인',
			},
			urlCheckStatus: {
				VALID_URL: '사용할 수 있는 URL',
				INVALID_URL: '정상적이지 않은 URL',
				DUPLICATED_URL: '이미 사용중인 URL',
			},
		};
	},
	methods: {
		checkSpecialChar(event) {
			const specificCharRegex = /[|,\\,",#,$,<,>,,,;]/;
			const value = event.target.value;
			return specificCharRegex.test(value);
		},
		checkInputLength(event, min, max) {
			// 입력값 글자수만 체크할 때
			return event.target.value.length < parseInt(min) || event.target.value.length > parseInt(max) ? false : true;
		},
		checkStrLength(str, min, max) {
			// 입력값 글자수만 체크할 때
			return str.length < parseInt(min) || str.length > parseInt(max) ? false : true;
		},
		checkInputLengthTrim(event, min, max) {
			// 입력값 글자수만 체크할 때 (양끝 공백 제거)
			const value = event.target.value.trim();
			return !(value.length < parseInt(min) || value.length > parseInt(max));
		},
		validInputLength(event, min, max) {
			return event.target.value.length < parseInt(min) || event.target.value.length > parseInt(max)
				? 'invalid'
				: 'valid';
		},
		checkId(data) {
			const regExp = /^([0-9a-z_\-.]{3,64})$/;
			if (!regExp.test(data)) {
				return false;
			}
			return true;
		},
		checkTelFormat(data) {
			data = data.replace(/[\\+-]/g, '');
			if (isNaN(data)) {
				return false;
			}
			return true;
		},
		checkPhone(phoneNumber) {
			let data = phoneNumber.replace(/[\\+-]/g, '');

			if (isNaN(data)) {
				return false;
			}
			if (data.length < 9) {
				return false;
			}
			const n1 = data.substring(0, 3);
			if (n1 == '010' && data.length < 10) {
				return false;
			}
			if (n1 !== '010' && data.length >= 10) {
				return false;
			}
			return true;
		},
		checkBusinessNumber(businessNumber) {
			businessNumber = businessNumber.replace(/[\\-]/g, ''); //숫자만 추출

			if (businessNumber == '9999999999') {
				return true;
			}
			if (businessNumber.length != 10) {
				return false; //반드시 10자리
			}
			let checkNo = '137137135';
			let sum = 0;

			if (isNaN(businessNumber)) {
				return false;
			}
			for (let i = 0; i < checkNo.length; i++) {
				let c = businessNumber.charAt(i);
				let cn = checkNo.charAt(i);
				sum += Number(c) * Number(cn);
			}
			sum += Math.floor((Number(businessNumber.charAt(8)) * 5) / 10);
			sum %= 10;
			sum = 10 - sum;
			return sum % 10 == Number(businessNumber.charAt(9));
		},
		checkEmail(email) {
			// 이메일 형식 체크
			if (email.length < 2 || email.length > 255) {
				return false;
			}
			if (email.indexOf('%') != -1) {
				return false;
			}
			if (email.indexOf('&') != -1) {
				return false;
			}
			if (email.indexOf('\\') != -1) {
				return false;
			}

			var dataArr = email.split('@');
			if (dataArr.length != 2) {
				return false;
			}
			// dataArr[0] : 로컬파트, dataArr[1] :도메인
			// 로컬 파트
			var local = dataArr[0];
			var localPattern = /[ㄱ-ㅎㅏ-ㅣ가-힣]/;
			if (localPattern.test(local)) {
				return false;
			}
			// 도메인
			var domain = dataArr[1];
			var domainPattern = /[\Wㄱ-ㅎㅏ-ㅣ가-힣]/;

			if (domainPattern.test(domain.replace(/[\\.-]/g, ''))) {
				return false;
			}
			var upperPattern = /[A-Z]/;
			if (upperPattern.test(email)) {
				return false;
			}
			const regExp = /^[0-9a-z]([-_.]?[0-9a-z])*@[0-9a-z]([-_.]?[0-9a-z])*.[a-z]{2,3}$/;

			if (email.match(regExp) != null) {
				return true;
			}
			return false;
		},
		checkInteger(num) {
			if (!num) {
				return true;
			}
			if (isNaN(num)) {
				return false;
			}
			const numberPattern = /^[0-9]+$/;
			if (numberPattern.test(num)) {
				return true;
			}
			return false;
		},
		checkBirth(birth) {
			const birthPattern = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/;
			return birth.match(birthPattern);
		},
	},
};
