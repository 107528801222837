<template>
	<!-- 해당 템플릿은 본인인증 미구현으로 테스트 폼을 만들어서 가상으로 진행하는 기능입니다. 나중에 제거 필요! -->
	<Fragment>
		<div v-if="isDev" class="body-wrap sign-up">
			<div class="contents-wrap">
				<div class="card">
					<h3>휴대폰 본인인증 CI 검증용 테스트 폼</h3>
					<div class="group-inp">
						<label for="testName" class="label">이름</label>
						<input type="text" v-model="testName" id="testName" class="" />
						<label for="testBirthday" class="label">생년월일</label>
						<input type="text" v-model="testBirthday" id="testBirthday" class="" />
						<label for="testPhoneNumber" class="label">휴대폰 번호</label>
						<input type="text" v-model="testPhoneNumber" id="testPhoneNumber" class="" />
						<label for="testCi" class="label">본인인증 CI</label>
						<input type="text" v-model="testCi" id="testCi" class="" />
						<label for="testSnsci" class="label">SNS CI</label>
						<input type="text" v-model="testSnsCi" id="testSnsci" class="" />
						<button class="line" @click="inputCi">저장하기</button>
					</div>
				</div>
			</div>
		</div>
	</Fragment>
</template>
<script>
/** mounted 속성을 제외하고는 모두 테스트용 script입니다. 나중에 제거 필요! */
import { SIGNUP_MODAL_STYLE } from '@/utils/constants';
import { findId } from '@/api/login';
import mixinsAlert from '@/components/common/mixinsAlert';
import PasswordReset from '@/components/login/PasswordReset';
import { ALREADY_SIGNED } from '@/temlplates';
import { IS_DEV } from '@/constants';

export default {
	mixins: [mixinsAlert],
	data() {
		return {
			isDev: IS_DEV,
			testEmail: '',
			loginId: '',
		};
	},
	mounted() {
		if (!IS_DEV) {
			const ci = this.$route.query.CI;
			const phoneNumber = this.$route.query.phoneNo;
			const birthday = this.$route.query.birthDay;
			const name = this.$route.query.name;
			const sendObj = {
				name,
				ci,
				phoneNumber,
				birthday,
			};
			window.opener.postMessage(sendObj, location.origin);
			window.close();
		}
	},
	methods: {
		inputCi() {
			const sendObj = {
				ci: this.testCi,
				birthday: this.testBirthday,
				phoneNumber: this.testPhoneNumber,
				name: this.testName,
			};
			if (this.testSnsCi && this.validateAccountCiBySns()) {
				this.alert({
					title: `<div class="popup-graphic"><i class="ic-80 ic-noti"></i></div>`,
					contents: `<div class="text-center">본인인증 결과, 입력하신 사용자 정보와 SNS 사용자 정보가 다릅니다. 회원가입을 다시 진행해주세요.</div>`,
					buttons: [
						{
							callback: () => {
								// @TODO: 테스트 이후에 replace로 변경
								window.close();
								// this.$emit('close');
								// this.$router.replace('/login');
							},
						},
					],
				});
			} else {
				window.opener.postMessage(sendObj, location.origin);
				window.close();
			}
		},
		validateAccountCiBySns() {
			// 해당 메서드는 추후 SignUp.vue > handleMessage로 옮겨져야 한다.
			return this.testCi !== this.testSnsCi;
		},
		async validateAlreadySignuped() {
			if (this.testCi && this.testName && this.testPhoneNumber && this.testBirthday) {
				try {
					const response = await findId({
						name: this.testName,
						phoneNumber: this.testPhoneNumber,
						birthDay: this.testBirthday,
						ci: this.testCi,
					});
					this.testEmail = response.data.email;
					this.loginId = response.data.loginId;
					this.alert({
						title: '회원가입',
						contents: ALREADY_SIGNED(this.testEmail, this.email, this.loginId),
						buttons: [
							{
								text: '로그인',
							},
							{
								text: '비밀번호 초기화',
								callback: () => {
									this.$modal.show(PasswordReset, {}, Object.assign({}, SIGNUP_MODAL_STYLE, { name: 'passwordReset' }));
								},
							},
						],
					});
				} catch (e) {
					if (e.data.errorCode === 'ERROR212' || e.data.errorCode === 'ERROR0202') {
						this.$emit('isVerified', true);
					}
					this.$toasted.show(e.data.errorMessage);
				}
			}
			return;
		},
	},
};
</script>

<style>
@import '../../assets/css/common.css';
@import '../../assets/css/signup.css';
</style>
