<template>
	<div>
		<div class="header">
			<h1 class="heading-3-b">비밀번호 초기화</h1>
			<button class="icon" @click="handleClose">
				<i class="ic-24 ic-close"></i>
			</button>
		</div>

		<div class="contents-wrap">
			<p class="body-2-r mb-24">본인 인증 후 회원 정보에 등록한 이메일로 임시 비밀번호를 보내드립니다.</p>

			<ul class="select-list">
				<li>
					<div class="group-rdo large">
						<input
							type="radio"
							id="testRadio01"
							name="testSelectOption"
							:checked="selectEmail"
							@click="() => (this.selectEmail = true)"
						/>
						<label for="testRadio01" class="text-option">
							<div class="option-list">
								<p class="text-main">이메일 인증</p>
								<div class="group column gap8" :class="{ blind: !selectEmail }">
									<div class="group-inp block">
										<input
											type="text"
											id="name"
											maxlength="50"
											class=""
											placeholder="이름"
											v-model.trim="name"
											@input="
												() => {
													if (name.length !== 0) {
														this.invalidClass.name = false;
													} else {
														this.invalidClass.name = true;
													}
												}
											"
										/>
										<p v-if="invalidClass.name" class="text-detail" :class="[invalidClass.name ? 'invalid' : 'valid']">
											<i class="ic-16" :class="[invalidClass.name ? 'ic-invalid' : 'ic-valid']"></i>이름을 입력해주세요
										</p>
									</div>
									<div class="group-inp block">
										<input
											type="text"
											id="birthday"
											class=""
											placeholder="회원정보에 등록한 생년월일 (ex19970415)"
											maxlength="10"
											v-model.trim="birthday"
											@input="validateBirth"
										/>
										<p
											v-if="invalidClass.birth"
											class="text-detail"
											:class="[invalidClass.birth ? 'invalid' : 'valid']"
										>
											<i class="ic-16" :class="[invalidClass.birth ? 'ic-invalid' : 'ic-valid']"></i>생년월일을
											확인해주세요
										</p>
									</div>
									<div class="group-inp block">
										<input
											type="text"
											id="email"
											maxlength="50"
											class=""
											placeholder="이메일 주소 (계정)"
											v-model.trim="email"
											@input="
												() => {
													this.invalidClass.email = !this.checkEmail(this.email);
												}
											"
										/>
										<p
											v-if="invalidClass.email"
											class="text-detail"
											:class="[invalidClass.email ? 'invalid' : 'valid']"
										>
											<i class="ic-16" :class="[invalidClass.email ? 'ic-invalid' : 'ic-valid']"></i>이메일 형식을
											확인해주세요
										</p>
									</div>
									<button
										class="solid"
										:class="{ disabled: !selectEmail }"
										:disabled="!selectEmail"
										@click="handleCompleteForEmail"
									>
										비밀번호 초기화
									</button>
								</div>
							</div>
						</label>
					</div>
				</li>
				<li>
					<div class="group-rdo large">
						<input
							type="radio"
							id="testRadio02"
							name="testSelectOption"
							:checked="!selectEmail"
							@click="() => (this.selectEmail = false)"
						/>
						<label for="testRadio02" class="text-option">
							<div class="option-list">
								<p class="text-main">현재 사용중인 휴대전화로 인증(본인인증)</p>
								<div class="group column gap8" :class="{ blind: selectEmail }">
									<IdentityVerificationButton v-on:complete="handleComplete"></IdentityVerificationButton>
								</div>
							</div>
						</label>
					</div>
				</li>
			</ul>
		</div>
		<!--  -->
		<div class="btn-area group-btn">
			<button class="line" @click="handleClose">취소</button>
		</div>
	</div>
</template>

<script>
import StepLayer from '@/components/login/StepLayer.vue';
import { resetPassword } from '@/api/login';
import mixinsAlert from '@/components/common/mixinsAlert';
import IdentityVerificationButton from '@/components/login/IdentityVerificationButton';
import { checkIdentity } from '@/components/login/mixinsFindUser';
import { CHECK_EMAIL } from '@/temlplates';
import { validation } from '@/components/apply/mixinsValidation';
import { checkEmail } from '@/plugins/custom/modules/validator';

export default {
	name: 'PasswordReset',
	components: { IdentityVerificationButton },
	mixins: [StepLayer, mixinsAlert, checkIdentity, validation],
	data() {
		return {
			name: '',
			email: '',
			ci: '',
			phoneNumber: '',
			birthday: '',
			modalName: 'passwordReset',
			selectEmail: true,
			invalidClass: {
				name: '',
				birth: '',
				email: '',
			},
		};
	},
	methods: {
		passwordResetSendEmail() {
			this.alert({
				title: '비밀번호 초기화',
				contents: CHECK_EMAIL(this.email),
				buttons: [
					{
						text: '비밀번호 초기화',
						callback: async () => {
							try {
								let response = await resetPassword({
									name: this.name,
									ci: this.ci,
									email: this.email,
									phoneNumber: this.phoneNumber,
									birthDay: this.birthday.replace(/-/g, ''),
								});

								this.$toasted.show('비밀번호 초기화 메일이 발송되었습니다.');
								this.$emit('close');
							} catch (e) {
								this.$toasted.show('비밀번호 초기화 메일 발송에 실패했습니다. 다시 시도해주세요');
							}
						},
					},
					{},
				],
			});
		},
		handleComplete(data) {
			let userData = {
				name: data.name,
				ci: data.ci,
				phoneNumber: data.phoneNumber,
				birthDay: data.birthday,
			};

			this.findUserByIdentityVerification(
				userData,
				null,
				(resp) => {
					this.name = data.name;
					this.email = resp.data.email;
					this.ci = data.ci;
					this.phoneNumber = data.phoneNumber;
					this.birthday = data.birthday;
					this.passwordResetSendEmail();
				},
				this.modalName,
			);
		},
		allValidationCheck() {
			if (this.name.length === 0) {
				this.invalidClass.name = true;
			} else {
				this.invalidClass.name = false;
			}

			if (this.birthday.length === 0 || !this.checkBirth(this.birthday)) {
				this.invalidClass.birth = true;
			} else {
				this.invalidClass.birth = false;
			}

			if (this.email.length === 0 || !checkEmail(this.email)) {
				this.invalidClass.email = true;
			} else {
				this.invalidClass.email = false;
			}

			return !this.invalidClass.name && !this.invalidClass.birth && !this.invalidClass.email;
		},
		async handleCompleteForEmail() {
			if (!this.allValidationCheck()) {
				return false;
			}

			let userData = {
				name: this.name,
				email: this.email,
				birthDay: this.birthday.replace(/-/g, ''),
			};

			await this.findUserByIdentityVerification(
				userData,
				null,
				(resp) => {
					this.email = resp.data.email;
					this.passwordResetSendEmail();
				},
				this.modalName,
			);
		},
		handleClose() {
			this.$modal.hide('passwordReset');
		},
		validateBirth(e) {
			let originBirth = e.currentTarget.value;
			let inputBirth = originBirth.replace(/[^0-9]/g, '');
			let length = inputBirth.length;
			let resultBirth = '';

			if (originBirth.length > 10) {
				this.birthday = originBirth.substring(0, 10);
			}

			for (let i = 0; i < 2; i++) {
				if (inputBirth.length > 4 && i === 0) {
					resultBirth += inputBirth.substring(0, 4) + '-';
					inputBirth = inputBirth.substring(4);
				} else if (length > 6 && inputBirth.length > 2 && i === 1) {
					resultBirth += inputBirth.substring(0, 2) + '-';
					inputBirth = inputBirth.substring(2);
				}
			}

			resultBirth += inputBirth;
			this.birthday = resultBirth;
			this.invalidClass.birth = !this.checkBirth(resultBirth);
		},
	},
};
</script>

<style scoped></style>
